<!--
 * @Author: 作者
 * @Date: 2024-01-25 15:19:04
 * @description: 文件描述
-->
<template>
  <div class="countdown-box">
    <div class="time-box">
      <span>{{ remainingSeconds }}</span>
    </div>
    <div class="content">{{ content }}</div>
    <img src="../assets/nian-beast-left.png" />
  </div>
</template>
<script>
export default {
  name: "CountdownWrapper",
  data() {
    return {
      // remainingSeconds: 80,
      remainingSeconds: 30,
      content:
        "本大爷拜年呢，来年保你财运亨通，顺顺利利，倒计时结束后下一位就可以进来啦",
    };
  },
  methods: {
    // 使用Promise实现倒计时
    countdown(seconds) {
      const _this = this;
      return new Promise((resolve) => {
        _this.remainingSeconds = seconds;
        let intervalId = setInterval(() => {
          if (_this.remainingSeconds === 0) {
            clearInterval(intervalId);
            resolve("倒计时结束");
          } else {
            _this.remainingSeconds--;
          }
        }, 1000);
      });
    },
  },
  mounted() {
    const _this = this;
    _this
      .countdown(this.remainingSeconds)
      .then((message) => {
        _this.$emit("closeCountdown", message);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
<style scoped>
.countdown-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.32rem 0.32rem 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.countdown-box .time-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.countdown-box .time-box span {
  width: 3.96rem;
  height: 3.96rem;
  background-image: linear-gradient(180deg, #fd6e4a 0%, #ff2a48 100%);
  border-radius: 100%;
  font-family: PingFangSC-Semibold;
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.countdown-box .content {
  font-family: PingFangSC-Regular;
  font-size: 0.32rem;
  color: #ffffff;
  text-align: center;
  line-height: 1.5;
}
img {
  width: 70%;
  padding-top: 0.32rem;
}
</style>
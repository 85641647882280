<template>
  <el-dialog
    class="confirm-dialog"
    :visible.sync="showDialog"
    :show-close="false"
    width="80%"
  >
    <div class="dialog-content">
      <img class="top-img" src="../assets/nian-beast-left.png" alt="" />
      <div class="content-text">
        <div class="title">{{ title }}</div>
        <div class="question">{{ dialogTitle }}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleConfirm(false)">取 消</el-button>
        <el-button type="primary" @click="handleConfirm(true)">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { ref } from "vue";
export default {
  name: "ConfirmDialog",
  props: {
    title: {
      type: String,
      default: "问题是否发出",
    },
    dialogTitle: {
      type: String,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    handleConfirm(bool) {
      this.$emit("dialogConfirmEvent", bool);
    },
  },
  mounted() {
    this.showDialog = ref(this.dialogVisible);
  },
  watch: {
    dialogVisible: function (newVal) {
      this.showDialog = newVal;
    },
  },
};
</script>
<style scoped>
.confirm-dialog ::v-deep .el-dialog {
  background-color: transparent;
  box-shadow: none;
}
.dialog-content {
  padding: 0 0.12rem 0.22rem 0.12rem;
  background-image: url("../assets/dialog-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0.4rem;
  text-align: center;
}
.top-img {
  height: 2.3rem;
  margin-top: -1.32rem;
}
.title {
  font-family: PingFangSC-Semibold;
  font-size: 0.28rem;
  color: #070c14;
  font-weight: 600;
  padding: 0.36rem 0 0.24rem 0;
}
.question {
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  color: #070c14;
  line-height: 1.75;
  text-align: left;
  max-height: 3rem;
  overflow-y: auto;
  padding: 0 0.2rem;
}
.dialog-content .dialog-footer {
  margin-top: 0.32rem;
}
.dialog-footer ::v-deep .el-button {
  height: 0.8rem;
  min-width: 2.12rem;
  border-radius: 0.4rem;
  margin-bottom: 0.1rem;
  border: none;
  font-family: PingFangSC-Regular;
  font-size: 0.28rem;
}
.dialog-footer ::v-deep .el-button--default {
  background: #ffd2d7;
  color: #070c14;
}
.dialog-footer ::v-deep .el-button--primary {
  background-image: linear-gradient(180deg, #fd6e4a 0%, #ff2a48 100%);
  columns: #fff;
}
</style>
<!--
 * @Author: 作者
 * @Date: 2024-01-24 18:05:04
 * @description: 文件描述
-->
<template>
  <div class="chat-content">
    <div id="chatTop" :class="['chat-top', { 'is-input': !showRecorder }]">
      <div class="chat-item" v-for="(item, idx) in chunkList" :key="idx">
        <div class="create-time" v-if="item.createTime">
          {{ item.createTime }}
        </div>
        <div
          :class="[
            'item-content',
            {
              left: item.type === 'reply' || item.type === 'customChoose',
              right: item.type === 'record',
            },
          ]"
        >
          <div class="head-img">
            <el-avatar
              shape="square"
              :src="
                item.type === 'record'
                  ? require('../assets/head-img.png')
                  : require('../assets/reply-img.png')
              "
            ></el-avatar>
          </div>
          <template v-if="item.type === 'customChoose'">
            <div class="chat-item-content">
              <div class="title">{{ item.title }}</div>
              <div class="content-item tip">{{ item.content }}</div>
              <div class="content-item tip">{{ item.tabsTitle }}</div>
              <Tabs :tabs="item.tabs" @tabsClick="tabClick" />
            </div>
          </template>
          <template v-if="item.type === 'record'">
            <div class="chat-item-content text">
              <div class="content-item">
                <div
                  v-if="item.stream"
                  class="is-voice"
                  @click="onPlay(idx)"
                  @touchend.prevent="onPlay(idx)"
                >
                  <img
                    v-if="item.wink"
                    class="operation"
                    src="../assets/white-play.svg"
                  />
                  <img
                    v-else
                    class="operation"
                    src="../assets/white-stop.svg"
                  />
                  <img
                    class="baiseyinfu"
                    v-show="!item.wink"
                    src="../assets/baiseyinfu.png"
                  />
                  <img
                    class="baiseyinfu"
                    v-show="item.wink"
                    src="../assets/baiseyinhu.gif"
                  />
                </div>
                <div v-if="item.stream && item.content" class="line"></div>
                <div class="is-text">{{ item.content }}</div>
              </div>
            </div>
          </template>
          <template v-if="item.type === 'reply'">
            <div class="chat-item-content text reply">
              <div class="content-item">
                <div
                  v-if="item.stream"
                  class="is-voice"
                  @click="onPlay(idx)"
                  @touchend.prevent="onPlay(idx)"
                >
                  <img
                    v-if="item.wink"
                    class="operation"
                    src="../assets/play.svg"
                  />
                  <img v-else class="operation" src="../assets/stop.svg" />
                  <img
                    class="baiseyinfu"
                    v-show="!item.wink"
                    src="../assets/heiseyinhu.png"
                  />
                  <img
                    class="baiseyinfu"
                    v-show="item.wink"
                    src="../assets/heiseyinhu.gif"
                  />
                </div>
                <div v-if="item.stream && item.content" class="line"></div>
                <div class="is-text">{{ item.content }}</div>
              </div>
            </div>
          </template>
          <template v-if="item.type === 'loading'">
            <div class="chat-item-content text is-loading">
              <div class="content-item text">
                正在生成<i class="el-icon-more"></i>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div id="recorderWrapper" class="recorder-wrapper">
      <div class="high-frequency-box">
        <img class="left-img" src="../assets/nian-beast.png" />
        <div class="right-text">
          <div style="display: flex" class="scroll">
            <span
              class="text-item"
              v-for="(item, idx) in highFrequencyList"
              :key="idx"
              @click="tabClick(item)"
              >{{ item.content }}</span
            >
          </div>
        </div>
      </div>
      <div v-if="showRecorder" class="record-box">
        <p>{{ btnText }}</p>
        <el-tooltip
          v-model="showShortRecording"
          class="item"
          effect="dark"
          content="说话时间太短"
          placement="top"
          :manual="true"
          :visible-arrow="false"
        >
          <div>
            <div
              :class="['record-btn recording', {'none': !recordering}]"
              @mousedown="onMousedown"
              @touchstart.prevent="onMousedown"
            ></div>
            <div
              :class="['record-btn record', {'none': recordering}]"
              @mousedown="onMousedown"
              @touchstart.prevent="onMousedown"
            ></div>
          </div>
        </el-tooltip>
        <img
          class="toggle"
          src="../assets/keyboard.svg"
          @click="showRecorder = false"
        />
      </div>
      <div v-else class="text-box input-box">
        <el-input v-model="searchVal" size="medium" maxlength="128">
          <img
            slot="suffix"
            class="record-img"
            src="../assets/audio.png"
            @click="showRecorder = true"
          />
        </el-input>
        <img class="opration-box" src="../assets/send.png" @click="sendText" />
      </div>
      <audio ref="audio"></audio>
    </div>
    <Countdown v-if="showCountdown" @closeCountdown="showCountdown = false" />
    <ConfirmDialog :dialogVisible="dialogVisible" :dialogTitle="dialogTitle" @dialogConfirmEvent="dialogConfirm" />
  </div>
</template>
<script>
import Tabs from "@/components/Tabs.vue";
// import { uploadFile, toUnit, audioToText, textToAudioSmallText, audioToTextAli, audiopPic } from "@/api/index";
import { toUnit, textToAudioSmallText, audioToTextAli } from "@/api/index";
import Countdown from "@/components/Countdown.vue";
import Recorder from 'js-audio-recorder';
import ConfirmDialog from "@/components/ConfirmDialog.vue";
var _time;
var _inTime;
// var oldPrefix = "http";
// var newPrefix = "https";

export default {
  name: "ChatContent",
  components: {
    Tabs,
    Countdown,
    ConfirmDialog
  },
  data() {
    return {
      searchVal: "", // 搜索的值
      sendDisabled: true,
      showRecorder: true,
      btnText: "按住说话",
      loading: false,
      showCountdown: false, // 是否显示倒计时
      recorder: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      }),
      recordering: false,
      chunks: [],
      chunkList: [
        {
          type: "customChoose",
          createTime: "",
          title: "你好，我是年兽",
          content: "欢迎咨询灯会的各种活动，恭祝大家新年快乐",
          tabsTitle: "你可以试着问我：",
          tabs: [
            {
              content: "自贡灯会历史",
              image: require("../assets/lantern.png"),
            },
            {
              content: "最佳观景台介绍",
              image: require("../assets/lantern.png"),
            },
            {
              content: "新春祝福语",
              image: require("../assets/lantern.png"),
            },
          ],
        },
      ],
      highFrequencyList: [
        {
          type: "customChoose",
          content: "彩灯展览位置",
        },
        {
          type: "customChoose",
          content: "如何查看灯会活动",
        },
        {
          type: "customChoose",
          content: "今年灯会的主题是什么",
        },
        {
          type: "customChoose",
          content: "在最佳观景台上可以欣赏到哪些灯组",
        },
      ],
      currentIndex: -1,
      isPlaying: false,
      willPlayIndex: -1,
      duration: 0,
      showShortRecording: false, // 提示录音太短
      dialogVisible: false, // 录音确认的弹窗
      dialogTitle: '',
    };
  },
  methods: {
    // requestAudioAccess() {
    //   navigator.mediaDevices.getUserMedia({ audio: true }).then(
    //     (stream) => {
    //       this.recorder = new window.MediaRecorder(stream);
    //       this.bindEvents();
    //     },
    //     () => {
    //       alert("出错，请确保已允许浏览器获取录音权限");
    //     }
    //   );
    // },
    onMousedown() {
      // if (e.target.tagName.toLowerCase() === "img") {
      //   e.preventDefault();
      // }
      if (!navigator.mediaDevices) {
        alert("您的浏览器不支持获取用户设备");
        return;
      }
      if (!window.MediaRecorder) {
        alert("您的浏览器不支持录音");
        return;
      }
      if (this.loading) return;
      document.addEventListener('touchend', this.onMouseup)
      document.addEventListener('mouseup', this.onMouseup)
      this.loading = true;
      this.onStart();
      this.btnText = "松开结束";
      clearTimeout(_time);
      _time = setTimeout(() => {
        document.removeEventListener('touchend', this.onMouseup)
        document.removeEventListener('mouseup', this.onMouseup)
        this.onStop();
      }, 60000);
      _inTime = setInterval(() => {
        this.duration++
      },1000)
    },
    onMouseup() {
      console.log('onMouseup')
      this.btnText = "按住说话";
      this.recordering = false;
      this.recorder.stop();
      clearInterval(_inTime);
      clearTimeout(_time);
      document.removeEventListener('touchend', this.onMouseup)
      document.removeEventListener('mouseup', this.onMouseup)
      if (this.duration <= 1) {
        this.loading = false;
        this.duration = 0
        this.showShortRecording = true;
        setTimeout(()=>{
          this.showShortRecording = false;
        }, 3000)
        return;
      }
      this.onStop();
      this.duration = 0
    },
    onStart() {
      const _this = this
      Recorder.getPermission().then(
        () => {
          navigator?.mediaDevices?.getUserMedia({ audio: true })
            .then(() => {
              console.log('麦克风未被占用');
              _this.recorder.start().then(res => {
                  console.log(res, 'console.log("开始录音");')
                }).catch(err => {
                  console.log(err, 'console.log("开始录音失败");')
                }); // 开始录音
              _this.recordering = true;
              _this.audio && this.audio.pause();
              _this.chunkList[_this.currentIndex] && _this.chunkList[_this.currentIndex].wink && _this.$set(_this.chunkList[_this.currentIndex], "wink", false);
            })
            .catch((error) => {
              if (error.name === 'NotFoundError') {
                console.log('没有找到任何音频输入设备');
              } else if (error.name === 'NotAllowedError' || error.message === 'Permission denied by system') {
                console.log('无法访问麦克风，因为已经被其他应用程序或进程占用了');
              } else {
                console.log(`发生错误：${error}`);
              }
            });
        },
        (error) => {
          alert("出错，请确保已允许浏览器获取录音权限");
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    onStop() {
      this.recordering = false;
      this.saveRecordingData();
      this.recorder.stop();
    },
    onPlay(index) {
      let item = this.chunkList[index];
      this.audio.currentTime = 0;
      if (this.currentIndex === index) {
        if (item.wink) {
          this.audio.pause();
          this.isPlaying = false;
          this.$set(item, "wink", false);
        } else {
          this.resetAudio(index);
        }
      } else {
        this.resetAudio(index);
      }
    },
    bindAudioEvent() {
      this.audio.onplay = () => {
        this.$set(this.chunkList[this.currentIndex], "wink", true);
      };
      this.audio.onpause = () => {
        this.$set(this.chunkList[this.currentIndex], "wink", false);
        this.isPlaying = false;
      };
      this.audio.onended = () => {
        this.$set(this.chunkList[this.currentIndex], "wink", false);
        this.isPlaying = false;
        if (this.willPlayIndex !== -1) {
          this.resetAudio(this.willPlayIndex);
          this.willPlayIndex = -1;
        }
      };
    },
    // bindEvents() {
    //   this.recorder.ondataavailable = this.getRecordingData;
    //   this.recorder.onstop = this.saveRecordingData;
    // },
    // getRecordingData(e) {
    //   this.chunks.push(e.data);
    // },
    saveRecordingData() {
      let blob = this.recorder.getWAVBlob(),
        audioStream = URL.createObjectURL(blob),
        //估算时长
        duration = this.duration;
      console.log('blob', blob)
      console.log("duration:", duration)
      const file = new File([blob], "audio.wav", { type: "audio/wav" });
      const fd = new FormData();
      fd.append("file", file);
      const fc = new FormData();
      fc.append("image", file);
      const _this = this;
      this.chunkList.push({
        type: "record",
        stream: audioStream,
        createTime: this.parseTime(new Date(), "{h}:{i}"),
        content: "正在生成",
      });
      this.scrollBottom();
      audioToTextAli(fc).then(rlt => {
        const _lent = _this.chunkList.length;
        if(rlt?.data) {
          _this.$set(_this.chunkList[_lent - 1], "content", rlt.data);
          // _this.addLoadingText();
          // _this.scrollBottom();
          // _this.toUnitFunc(_this.chunkList.length - 1, rlt.data);
          _this.openDialog(rlt.data)
        } else {
          _this.$set(_this.chunkList[_lent - 1], "content", '');
          this.loading = false;
        }
      }).catch(() => {
        _this.resetStatus();
      });
      // uploadFile(fd)
      this.chunks = [];
    },
    // 确认弹窗回调
    dialogConfirm(bool) {
      if(bool) {
        this.onPlay(this.chunkList.length - 1);
        this.addLoadingText();
        this.scrollBottom();
        this.toUnitFunc(this.chunkList.length - 1, this.dialogTitle);
      } else {
        this.loading = false;
      }
      this.dialogVisible = false;
    },
    // 打开语音确认弹窗
    openDialog(dialogTitle) {
      this.dialogVisible = true;
      this.dialogTitle = dialogTitle;
    },
    // 发送文字
    sendText(val) {
      const _this = this;
      if (_this.loading) return;
      _this.loading = true; // 等待返回结果
      let value = this.searchVal;
      if (typeof val === "string" && val) {
        value = val;
      } else {
        if (!this.searchVal) {
          this.$message({type: 'error', message: "请输入您需要咨询的问题"});
          return;
        }
      }
      this.chunkList.push({
        type: "record",
        content: value,
      });
      this.searchVal = "";
      _this.addLoadingText();
      _this.scrollBottom();
      const lent = _this.chunkList.length;
      _this.textToAudioFunc(lent - 2, value, () => {
        _this.toUnitFunc(lent - 1, value);
      });
    },
    // 回复文字和回复语音
    toUnitFunc(idx, val) {
      const _this = this;
      toUnit({ q: val }).then((res) => { // 文字提问，回复文字
        // if (res.data.body && res.data.body.length > 60) {
        //   textToAudio({ content: res.data.body, type: '1' }).then((rlt) => {
        //     var audioStream = rlt.data.replace(new RegExp(`${oldPrefix}:`, 'g'), `${newPrefix}:`);
        //     _this.$set(_this.chunkList[idx], "type", "reply");
        //     _this.$set(_this.chunkList[idx], "content", res.data.body);
        //     _this.$set(_this.chunkList[idx], "stream", audioStream);
        //     if (!res.data.flag) {
        //       _this.showCountdown = true;
        //     }
        //     !_this.isPlaying ? _this.resetAudio(idx) : (this.willPlayIndex = idx);
        //     _this.loading = false;
        //     _this.scrollBottom();
        //   })
        // }else {
        textToAudioSmallText({ content: res.data.body, type: '1' }).then((rlt) => {
          var audioStream = URL.createObjectURL(rlt);
          _this.$set(_this.chunkList[idx], "type", "reply");
          _this.$set(_this.chunkList[idx], "content", res.data.body);
          _this.$set(_this.chunkList[idx], "stream", audioStream);
          if (!res.data.flag) {
            _this.showCountdown = true;
          }
          !_this.isPlaying ? _this.resetAudio(idx) : (this.willPlayIndex = idx);
            _this.loading = false;
          _this.scrollBottom();
        }).catch(() => {
          _this.resetStatus();
        });
        // }
      }).catch(() => {
        _this.resetStatus();
      });
    },
    // 文字转语音 TODO
    async textToAudioFunc(idx, val, callback) {
      const _this = this;
      const param = { content: val, type: '2' };
      textToAudioSmallText(param).then((res) => {
        callback && callback();
        var audioStream = URL.createObjectURL(res);
        _this.$set(_this.chunkList[idx], "type", "record");
        _this.$set(_this.chunkList[idx], "content", val);
        _this.$set(_this.chunkList[idx], "stream", audioStream);
        !_this.isPlaying ? _this.resetAudio(idx) : (this.willPlayIndex = idx);
        _this.scrollBottom();
      }).catch(() => {
        _this.resetStatus();
      });
    },
    resetAudio(index) {
      const idx = this.chunkList.findIndex((i) => i.wink == true);
      if (idx !== -1) {
        this.$set(this.chunkList[idx], "wink", false);
      }
      this.isPlaying = true;
      this.audio.src = this.chunkList[index].stream;
      this.currentIndex = index;
      this.audio.play();
      this.$set(this.chunkList[index], "wink", true);
    },
    // 重置状态
    resetStatus() {
      this.loading = false;
    },
    tabClick(item) {
      this.sendText(item.content);
    },
    // 等待的文本
    addLoadingText() {
      this.chunkList.push({
        type: "loading",
        content: "正在生成...",
      });
    },
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string" && /^[0-9]+$/.test(time)) {
          time = Number(time);
        } else if (typeof time === "string") {
          time = time
            .replace(new RegExp(/-/gm), "/")
            .replace("T", " ")
            .replace(new RegExp(/\.[\d]{3}/gm), "");
        }
        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        if (result.length > 0 && value < 10) {
          value = "0" + value;
        }
        return value || 0;
      });
      return time_str;
    },
    scrollBottom() {
      // 获取滚动条所在的元素
      this.$nextTick(() => {
        var element = document.getElementById("chatTop");
        // 将滚动条滚动到底部
        element.scrollTop = element.scrollHeight;
      });
    },
  },
  mounted() {
    this.audio = this.$refs.audio;
    // this.requestAudioAccess();
    this.bindAudioEvent();
    // const _this = this
    // Recorder.getPermission().then(
    //   () => {
    //     _this.recorder.start().then(res => {
    //       console.log(res, 'console.log("开始录音");')
    //       _this.recorder.stop()
    //     }).catch(err => {
    //       console.log(err, 'console.log("开始录音失败");')
    //     }); // 开始录音
    //   },
    //   (error) => {
    //     alert("出错，请确保已允许浏览器获取录音权限");
    //     console.log(`${error.name} : ${error.message}`);
    //   }
    // );
    // document.oncontextmenu=function(e){
    //     e.preventDefault();
    // };
  },
};
</script>
<style scoped>
/deep/ .el-scrollbar__view {
  display: flex;
}
.chat-content {
  position: relative;
  width: 100%;
  flex-direction: column;
  display: flex;
  height: calc(100% - 0.6rem);
  flex: 1;
}
.chat-top {
  height: calc(100% - 4.4rem);
  overflow-y: auto;
}
.chat-top.is-input {
  height: calc(100% - 3.2rem);
}
.item-content {
  display: flex;
}
.item-content.left {
  padding-right: 1.12rem;
}
.item-content.right {
  flex-direction: row-reverse;
  padding-left: 1.12rem;
}
.create-time {
  font-family: PingFangSC-Regular;
  font-size: 0.22rem;
  color: #5b5f66;
  font-weight: 400;
  text-align: center;
  padding: 0.32rem 0;
}
.chat-item {
  margin-bottom: 0.32rem;
}
.chat-item-content {
  background: #ffffff;
  padding: 0.32rem;
  border-radius: 0.12rem;
}
.chat-item-content .title {
  font-family: PingFangSC-Semibold;
  font-size: 0.32rem;
  color: #070c14;
  line-height: 1.5;
  font-weight: 600;
  padding-bottom: 0.16rem;
}
.chat-item-content .content-item {
  font-family: PingFangSC-Regular;
  font-size: 0.22rem;
  color: #6c7093;
  font-weight: 400;
  word-break: break-all;
}
.chat-item-content .content-item.tip {
  padding-bottom: 0.16rem;
}
.chat-item-content.text {
  background: #ff2a48;
  border-radius: 0.06rem;
  padding: 0.12rem 0.24rem;
  align-items: center;
  justify-content: center;
  display: flex;
}
.chat-item-content.text .content-item {
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  color: #ffffff;
}
.chat-item-content.is-loading {
  background: transparent;
  border: none;
}
.chat-item-content.is-loading .content-item {
  font-size: 0.24rem;
  color: #151b26;
}
.chat-item-content.is-loading ::v-deep .el-icon-more {
  margin-left: 0.16rem;
  color: #ff2a48;
}
.chat-item-content.text .content-item .is-voice {
  font-size: 0.4rem;
  padding-bottom: 0.08rem;
  display: flex;
  align-items: center;
}
.chat-item-content.text .is-voice .operation {
  width: 0.4rem;
  height: 0.4rem;
  padding-right: 0.08rem;
}
.chat-item-content.text .content-item .is-text {
  padding-top: 0.08rem;
  line-height: 0.38rem;
}
.chat-item-content.text .content-item .line {
  height: 0.02rem;
  background-image: url("../assets/white-line.svg");
  background-repeat: repeat-x;
}
.chat-item-content.text.reply .content-item .line {
  height: 0.02rem;
  background-image: url("../assets/line.svg");
}
.chat-item-content.reply {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #070c14;
}
.chat-item-content.reply .content-item {
  color: #070c14;
}
.head-img {
  padding: 0 0.16rem 0 0.32rem;
}
.head-img ::v-deep .el-avatar {
  background-color: transparent;
  transform: scaleX(-1);
  height: 0.64rem;
  width: 0.64rem;
}
.item-content.right .head-img {
  padding: 0 0.32rem 0 0.16rem;
}
.recorder-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.input-box {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.32rem;
  box-sizing: border-box;
  box-shadow: 0 0 0.08rem rgba(0, 0, 0, 0.1);
}
.input-box ::v-deep .el-input .el-input__inner {
  height: 0.72rem;
  line-height: 0.72rem;
  padding-right: 0.88rem;
  background: #eff1f3;
  border: none;
  border-radius: 0.72rem;
}
.input-box ::v-deep .el-input .el-input__suffix {
  right: 0.32rem;
}
.input-box ::v-deep .el-input i {
  font-size: 1.25rem;
  color: #0056ff;
}
.input-box .record-img {
  height: 0.38rem;
  width: auto;
  padding: 0.16rem 0 0 0;
}
img.opration-box {
  width: 0.72rem;
  margin-left: 0.32rem;
}
.record-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding-top: 0.29rem;
  padding-bottom: 0.1rem;
  position: relative;
  padding-bottom: 0.29rem;
}
.record-box p {
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  color: #6c7093;
}
.record-box .toggle {
  position: absolute;
  right: 0.32rem;
  top: 0.32rem;
  width: 0.48rem;
  height: 0.48rem;
}
.record-btn {
  height: 1.88rem;
  width: 1.88rem;
}
.high-frequency-box {
  display: flex;
  height: 1.08rem;
  overflow-y: hidden;
}
.left-img,
.right-text {
  display: inline-block;
}
.left-img {
  width: 1.58rem;
  height: 1.58rem;
}
.right-text {
  width: calc(100vw - 1.58rem);
  display: flex;
  align-items: center;
}
.scroll {
  display: flex;
  width: 100%;
  overflow-x: auto;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.right-text .text-item {
  display: inline-block;
  padding: 0.08rem 0.16rem;
  margin: 0 0.24rem 0 0;
  background: rgba(255, 255, 255, 0.67);
  border: 0.01rem solid rgba(255, 255, 255, 1);
  border-radius: 0.06rem;
  font-size: 0.24rem;
  flex-shrink: 0;
  text-wrap: nowrap;
}
.text-box {
  display: flex;
}
.record {
  background: url("../assets/record.png");
  background-size: contain;
}
.recording {
  background: url("../assets/recording.gif");
  background-size: contain;
}
.none {
  z-index: -1;
  position: absolute;
}
</style>
<style>
.sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: auto;
}
.is-vertical {
  display: none;
}
.baiseyinfu {
  width: 2.4rem;
}
</style>
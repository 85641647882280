<!--
 * @Author: 作者
 * @Date: 2024-01-26 09:11:14
 * @description: 文件描述
-->
<template>
  <div id="app">
    <HeaderNav />
    <ChatContent />
  </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue";
import ChatContent from "@/components/ChatContent.vue";
export default {
  name: "App",
  components: {
    HeaderNav,
    ChatContent,
  },
  data() {
    return {
      mediaRecorder: null,
      recordedChunks: [],
      isRecording: false,
    };
  },
  mounted() {
    this.setHtmlFontSize();
  },
  methods: {
    setHtmlFontSize() {
      let clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (clientWidth > 460) {
        clientWidth = 460;
      } else if (clientWidth < 320) {
        clientWidth = 320;
      }
      document.documentElement.style.fontSize = (clientWidth / 7.5) * 1 + "px";
    },
  },
};
</script>

<style>
* {
  margin: 0;
}

html,
body {
  height: 100%;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: #fff2f2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
#app::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url("./assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

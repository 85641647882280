import axios from 'axios'
import { Notification, Message } from 'element-ui'
// import { getToken } from '@/utils/auth'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL:'/api', // 'http://120.48.161.236:8020',//'/api',
  // 超时
  timeout: 1000*60*60*2,
})
// request拦截器
// service.interceptors.request.use(config => {
//   // 是否需要设置 token
//   const isToken = (config.headers || {}).isToken === false
//   if (!isToken) {
//     config.headers['Authorization'] = 'Bearer ' + '' // 让每个请求携带自定义token 请根据实际情况自行修改
//   }
//   return config
// }, error => {
//   console.log(error)
//   Promise.reject(error)
// })

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 0;
  // 获取错误信息
  const message = res.data.msg || res.data.message
  if (res.data instanceof Blob) {
    return Promise.resolve(res.data)
  }
  if (code === -401) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message)).catch(() => { })
  } else if (code === 500) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message)).catch(() => { })
  } else if (code !== 200) {
    Notification.error({
      title: message,
    })
    return Promise.reject(res.data)
  } else {
    return Promise.resolve(res.data)
  }
},
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

<!--
 * @Author: liumengmin
 * @Date: 2024-01-25 20:45:38
 * @LastEditors: liumengmin
 * @LastEditTime: 2024-01-28 11:13:50
 * @description: 
-->
<template>
  <div class="tabs">
    <div
      v-for="(item, index) in tabs"
      :class="{ tabs_item: true, active: activeIndex === index }"
      :key="index"
      @click="tabsClick(index, item)"
    >
      <el-image
        v-if="item.image"
        class="tabs_icon"
        :src="item.image"
      ></el-image>
      <div class="tabs_content">{{ item.content }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TabsWrapper",
  props: {
    tabs: {
      type: Array,
      default: () => [
        {
          content: "灯盏时间",
          image: "",
        },
        {
          content: "灯盏活动",
          image: "",
        },
      ],
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    tabsClick(index, item) {
      this.activeIndex = index;
      this.$emit("tabsClick", item);
    },
  },
};
</script>
<style scoped>
.tabs_item {
  display: flex;
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  font-weight: 400;
  padding: 0.16rem 0.24rem;
  margin-bottom: 0.24rem;
  cursor: pointer;
  background: #f6f7f9;
  border-radius: 0.06rem;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}
.tabs_icon {
  height: 0.56rem;
  margin-right: 0.08px;
}
.tabs_item.active {
  color: #FF2A48;
  background-image: linear-gradient(270deg, rgba(253,123,58,0.11) 0%, rgba(255,42,72,0.12) 94%);
}
.tabs_content {
  padding-left: 0.04rem;
}
</style>
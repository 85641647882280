/*
 * @Author: 作者
 * @Date: 2024-01-29 09:59:55
 * @description: 接口
 */
import request from '@/utils/request'
// 上传音频（废弃）
export function uploadFile(query) {
  return request({
    url: '/audio/uploadFile',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
// 语音转文字
export function audioToTextAli(query) {
  return request({
    url: '/audio/audioToTextAli',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
// （废弃）
export function audiopPic() {
  return request({
    url: '/audio/plc',
    method: 'post',
  })
}
// 文字提问，回复文字
export function toUnit(data) {
  return request({
    url: '/audio/toUnit',
    method: 'post',
    data: data
  })
}
/**
 * @description: 文字转语音（大于60字）
 * @param {content: 内容,type: 1  年兽 2  灯妹儿}
 * @return {*}
 */
export function textToAudio(data) {
  return request({
    url: '/audio/textToAudio',
    method: 'post',
    data: data
  })
}
// 语音转文字
export function audioToText(data) {
  return request({
    url: '/audio/audioToText',
    method: 'post',
    params: data
  })
}
/**
 * @description: 文字转语音（小于等于60字）
 * @param {content: 内容,type: 1  年兽 2  灯妹儿}
 * @return {*}
 */
export function textToAudioSmallText(data) {
  return request({
    url: '/audio/textToAudioSmallText',
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}

<!--
 * @Author: 作者
 * @Date: 2024-01-25 15:19:04
 * @description: 文件描述
-->
<template>
  <div class="header-wrapper">年兽贺岁</div>
</template>
<script>
export default {
  name: "HeaderNav",
};
</script>
<style scoped>
.header-wrapper {
    font-family: PingFangSC-Medium;
    font-size: 0.38rem;
    color: #FFFFFF;
    position: relative;
    text-align: center;
    font-weight: 500;
    padding: 0.32rem 0;
}
</style>